import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowEqual from 'shallowequal';
import difference from 'lodash/difference';
import flattenDeep from 'lodash/flattenDeep';
const s = require('./IsotopeContainer.module.scss');

let Isotope = null;
let iso = null; 
export default class IsotopeContainer extends Component {
      
  constructor(props) {
    super(props);
  
    this.isoOptions = {
      itemSelector: `.${s.articles__item}`,
      stamp: `.${s.stamp}`,
      layoutMode: 'masonry',
      percentPosition: false,
      transitionDuration: '0s',
      stagger: 0,
      masonry: {
        columnWidth: `.${s.articles__column}`,
        gutter: `.${s.articles__gutter}`,
        horizontalOrder: true
      },
      getSortData: {
        articles__item: '.articles__item'
      },
      sortBy: 'articles__item',
      sortAscending: true,
      animationEngine: 'best-available'
    };
  }
  
  componentDidMount() {
    this.createIsotopeContainer();
    this.arrangeLayout();
  }
  
  shouldComponentUpdate(nextProps, nextState) {
    return !shallowEqual(this.props, nextProps) || !shallowEqual(this.state, nextState);
  }
  
  componentDidUpdate(prevProps) {
    const prevChildren = flattenDeep(prevProps.children);
    const currentChildren = flattenDeep(this.props.children);
    
    // The list of keys seen in the previous render
    const currentKeys = prevChildren.map(n => n.key);
    
    // The latest list of keys that have been rendered
    const newKeys = currentChildren.map(n => n.key);
    
    // Find which keys are new between the current set of keys and any new children passed to this component
    const addKeys = difference(newKeys, currentKeys);
    
    
    // Find which keys have been removed between the current set of keys and any new children passed to this component
    const removeKeys = difference(currentKeys, newKeys);
    if (removeKeys.length > 0) {
      removeKeys.forEach(removeKey => iso.remove(document.getElementById(removeKey)));
      iso.arrange();
    }
    if (addKeys.length > 0) {
      iso.addItems(addKeys.map(addKey => document.getElementById(addKey)));
      iso.arrange();
    }
    
    if (this.props.arrange) {
      this.arrangeLayout();
      this.props.toggleArrangeIsotope();
    }
  }
  
  componentWillUnmount() {
    if (iso !== null) {
      iso.destroy();
    }
  }

  createIsotopeContainer() {
    if (typeof window !== "undefined") {
      // browser code
      Isotope = require('isotope-layout');
      iso = new Isotope("#articles", this.isoOptions)
    }
  }

      
  arrangeLayout = () => {
    // Only arrange if there are elements to arrange
    if (undefined !== this.props.children && this.props.children.length > 0) {
      try {
        window.dispatchEvent(new Event('resize'));
        iso.arrange();
      } catch (e) {
        console.log(e);
      }
    }
  };

  render() {
    const { children } = this.props;
    return (
      <div className={s.wrap}>
        <div className={s.article}>
          <div className={s.articles} id="articles" ref="isotopeContainer">
            <div className={s.articles__column} />
            <div className={s.articles__gutter} />
            {children}
            {!children && <p className={s.noData}>No Articles</p>}
          </div>
        </div>
      </div>
    );
  }
}

IsotopeContainer.propTypes = {
  children: PropTypes.node,
  toggleArrangeIsotope: PropTypes.func,
  arrange: PropTypes.bool,
  // ref: PropTypes.number,
};